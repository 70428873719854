import { Container } from "@mui/material";
import { graphql } from "gatsby";
import React from "react";
import BlockContent from "../components/block-content";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export interface TermsAndConditionsPageModel {
  title: string;
  seo: string;
  _rawDescription: any;
}

export const query = graphql`
  query TermsAndConditionsQuery {
    site: sanityTermsAndConditionsPage {
      _rawDescription
      seo
      title
    }
  }
`;

const TermsAndConditionPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  const site: TermsAndConditionsPageModel = (data || {}).site;
  return (
    <Layout>
      <SEO title={site.title} description={site.seo} />
      <Container role="main">
        <h1>{site.title}</h1>
        {site._rawDescription && <BlockContent blocks={site._rawDescription || []} />}
      </Container>
    </Layout>
  );
};

export default TermsAndConditionPage;
